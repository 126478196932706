import React from "react"
import "../scss/index.scss"
import Layout from "../components/layout/layout"
import Header from "../components/layout/header"
import { Helmet } from "react-helmet"
import Footer from "../components/layout/footer"
import HeroInner from "../components/hero/hero-inner"
import Image1 from "../../static/img/water-notice-eco-car-wash.jpg"

export default function WaterRestriction () {
    return <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Water Restriction - Eco Car Wash | Anywhere Anytime We Come To You</title>
          <description>Auckland wide there are millions of vehicles on the roads every day. They are getting dirty with daily driven. It is very important to clean and maintain them because this is your investment. Every vehicle should clean once a week or every fortnightly or must be every four weeks time. Also upholstery and carpet need to clean</description>
          <link rel="canonical" href="https://ecocarwash.co.nz" />
        </Helmet>
  <Header />
  <Layout>
  {/* Hero Block */}
  <HeroInner  title="Water Restriction Notice" description="Anywhere Anytime We Come to You" background={Image1} />
  <div className="container">
    <p>During Stage 1 water restrictions people can’t wash their cars, trucks fleet, at home unless they have recycled water (non-portable or non-drinking water).<br />
        It is very tough time for everyone but we need to save drinking water. So Eco Car Wash has a solution for you.<br />
        We have enough recycle water (non- portable or non drinking water) storage in our van so we are able to wash 8 to 10 small and medium types of cars so don’t worry about the water.<br /> <br />
        If you have any questions just contact our friendly team who are more than able to help. <br /> <br />
        Thanks for choosing Eco Car Wash.
    </p>
  </div>
  </Layout>
  <Footer copyright="Copyright &copy; 2020 Eco Car Wash. All Rights Reserved" />

  </>
}